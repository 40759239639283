import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppointmentGuard } from 'projects/core/src/lib/guards/appointment.guard';
import { AutoIdentRedirectGuard } from 'projects/core/src/lib/guards/autoident-redirect.guard';
import { LandingpageModuleGuard } from 'projects/core/src/lib/guards/modules/landingpage-module.guard';
import { LegalNoticeModuleGuard } from 'projects/core/src/lib/guards/modules/legal-notice-module.guard';
import { PrivacyPolicyModuleGuard } from 'projects/core/src/lib/guards/modules/privacy-policy-module.guard';
import { PortalGuard } from 'projects/core/src/lib/guards/portal.guard';
import { PublicAppointmentModuleGuard } from 'projects/core/src/lib/guards/public-appointment-booking.guard';
import { SessionTimeoutActiveGuard } from 'projects/core/src/lib/guards/session-timeout-active.guard';
import { TermsOfServicePageGuard } from 'projects/core/src/lib/guards/terms-of-service-page.guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('projects/patientenportal/src/app/pages/landingpage/landing.module').then(
        (m) => m.LandingPageModule,
      ),
    canActivate: [LandingpageModuleGuard],
  },
  {
    path: 'portal',
    loadChildren: () => import('./pages/portal/portal.module').then((m) => m.PortalPageModule),
    canActivate: [PortalGuard, AppointmentGuard],
  },
  {
    path: 'imprint',
    loadChildren: () =>
      import('projects/shared/app/pages/imprint/imprint.module').then((m) => m.ImprintPageModule),
    canActivate: [LegalNoticeModuleGuard],
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('projects/shared/app/pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule,
      ),
    canActivate: [PrivacyPolicyModuleGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('projects/shared/app/pages/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule,
      ),
    canActivate: [TermsOfServicePageGuard],
  },
  {
    path: 'session-timeout',
    loadChildren: () =>
      import('./pages/session-timeout/session-timeout.module').then(
        (m) => m.SessionTimeoutPageModule,
      ),
    canActivate: [SessionTimeoutActiveGuard],
  },
  {
    path: 'appointment-booking',
    loadChildren: () =>
      import('./pages/public-appointment-booking/public-appointment-booking.module').then(
        (m) => m.PublicAppointmentBookingPageModule,
      ),
    canActivate: [PublicAppointmentModuleGuard],
  },
  {
    path: 'autoident-redirect/:status/:id',
    loadChildren: () =>
      import('./pages/auto-ident-redirect/auto-ident-redirect.module').then(
        (m) => m.AutoidentRedirectPageModule,
      ),
    canActivate: [AutoIdentRedirectGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
