<lib-auto-layout direction="vertical" class="fill-height" [gap]="[20]" horizontal="stretch">
  <lib-auto-layout class="toolbar-container" [gap]="[20]" vertical="center">
    <lib-search-input
      [disabled]="!treatmentsList?.length"
      [searchParams]="searchParams"
      [grow]="1"
      (searchParamsChanged)="refreshViewItems()">
    </lib-search-input>
    @if (isCreateTreatmentVisible) {
      <lib-auto-layout class="toolbar-item card-layout" [gap]="[1]">
        <ion-button
          class="p3 treatment-creation ion-no-margin"
          color="primary"
          (click)="newTreatment()"
          data-api-id="treatment-creation">
          <fa-icon size="xs" slot="start" [icon]="icons.plus"></fa-icon>
          {{ 'general.create' | translate }}
        </ion-button>
      </lib-auto-layout>
    }
  </lib-auto-layout>

  <ion-card id="treatments-card" color="light" class="ion-no-margin stretch-container stretch-flex">
    <ng-container *ngIf="viewList && !isLoading">
      @if (viewList?.length) {
        <ion-list class="stretch-container fill-height" data-api-id="treatment-list">
          <cdk-virtual-scroll-viewport
            #scrollViewPort
            itemSize="56"
            [minBufferPx]="virtualScrollService.getBufferPx('min')"
            [maxBufferPx]="virtualScrollService.getBufferPx('max')"
            class="stretch-flex">
            <div
              class="list-head ion-hide-md-down"
              [style.top]="tableOrganizerService.getInverseViewportTopPosition(viewPort)">
              <ion-button
                id="sort-by-title"
                size="small"
                color="light"
                [disabled]="!!searchParams.criterions"
                (click)="setSort('title')">
                <h3 class="uppercase">{{ 'general.description' | translate }}</h3>
                @if (!searchParams.criterions) {
                  @if (checkSortParams('title', true)) {
                    <fa-icon slot="end" [icon]="icons.asc"></fa-icon>
                  }
                  @if (checkSortParams('title', false)) {
                    <fa-icon slot="end" [icon]="icons.desc"></fa-icon>
                  }
                }
              </ion-button>
              <ion-button
                id="sort-by-date"
                size="small"
                color="light"
                [disabled]="!!searchParams.criterions"
                (click)="setSort('date')">
                <h3 class="uppercase">{{ 'general.date' | translate }}</h3>
                @if (!searchParams.criterions) {
                  @if (checkSortParams('date', true)) {
                    <fa-icon slot="end" [icon]="icons.asc"></fa-icon>
                  }
                  @if (checkSortParams('date', false)) {
                    <fa-icon slot="end" [icon]="icons.desc"></fa-icon>
                  }
                }
              </ion-button>
            </div>
            <div *cdkVirtualFor="let treatment of viewList">
              <lib-treatment-row
                [treatment]="treatment"
                (treatmentClicked)="treatmentClicked.emit($event)"
                class="stretch-flex stretch-container">
              </lib-treatment-row>
            </div>
          </cdk-virtual-scroll-viewport>
        </ion-list>
      } @else {
        @if (isDataAccessRestricted) {
          <lib-auto-layout direction="vertical" [grow]="1">
            <lib-notification-box-component
              [grow]="1"
              [icon]="'user-lock'"
              [message]="'shared.treatments.no-treatments-access' | translate">
            </lib-notification-box-component>
          </lib-auto-layout>
        } @else if (searchParams?.criterions?.length) {
          <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1">
            <lib-notification-box-component
              [grow]="1"
              [message]="'shared.treatments.try-another-search-input' | translate">
            </lib-notification-box-component>
          </lib-auto-layout>
        } @else {
          <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="no-result">
            <lib-notification-box-component
              [grow]="1"
              [message]="'shared.treatments.no-treatments-available' | translate">
            </lib-notification-box-component>
          </lib-auto-layout>
        }
      }
    </ng-container>

    @if (isLoading) {
      <ion-accordion-group class="skeleton" [multiple]="true">
        <ion-accordion
          *ngFor="let i of getMaxSkeletonAmountAsArray()"
          class="pointer-event-none hide-accordion-toggle-icon">
          <ion-item color="light" slot="header" lines="none">
            <ion-row class="accordion-item-header">
              <ion-col sizeMd="9" size="12">
                <p>
                  <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
                </p>
              </ion-col>
              <ion-col class="ion-hide-md-down">
                <p>
                  <ion-skeleton-text class="rounded-small like-p" animated></ion-skeleton-text>
                </p>
              </ion-col>
            </ion-row>
          </ion-item>
        </ion-accordion>
      </ion-accordion-group>
    }
  </ion-card>
</lib-auto-layout>
