import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleInfo,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import MiniSearch from 'minisearch';
import { SearchParams, SortDirection, SortParams } from 'projects/core/src/lib/models/shared.model';
import { TreatmentDetails } from 'projects/core/src/lib/models/treatment.model';
import { ItemSearcherService } from 'projects/core/src/lib/services/item-searcher.service';
import { ItemSorterService } from 'projects/core/src/lib/services/item-sorter.service';
import { LoadingService } from 'projects/core/src/lib/services/loading.service';
import { SkeletonService } from 'projects/core/src/lib/services/skeleton.service';
import { TableOrganizerService } from 'projects/core/src/lib/services/table-organizer.service';
import { VirtualScrollService } from 'projects/core/src/lib/services/virtual-scroll.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-treatments-browser',
  templateUrl: './treatments-browser.component.html',
  styleUrls: ['./treatments-browser.component.scss'],
})
export class TreatmentsBrowserComponent {
  @ViewChild('scrollViewPort') viewPort: CdkVirtualScrollViewport;

  @Input() set treatments(treatments: TreatmentDetails[]) {
    this.treatmentsList = treatments;
    this.refreshViewItems();
  }
  @Input() isCreateTreatmentVisible: boolean;
  @Input() isLoading: boolean = true;
  @Input() isDataAccessRestricted: boolean;

  @Output() treatmentClicked = new EventEmitter<number>();
  @Output() createTreatmentClicked = new EventEmitter<void>();

  readonly MAX_ROW_AMOUNT: number = SkeletonService.getMaxRowAmount(190, 64);
  readonly icons = {
    asc: faCircleArrowDown,
    desc: faCircleArrowUp,
    info: faCircleInfo,
    plus: faPlus,
  };

  treatmentsList: TreatmentDetails[];
  viewList: TreatmentDetails[];

  searchParams = new SearchParams();
  sortParams = new SortParams();
  searchHandler: MiniSearch<TreatmentDetails>;

  constructor(
    public readonly virtualScrollService: VirtualScrollService,
    public readonly tableOrganizerService: TableOrganizerService,
    private readonly loadingService: LoadingService,
    private readonly translateService: TranslateService,
    private readonly searchService: ItemSearcherService,
  ) {
    this.initializeFilterAndSortParameters();
  }

  getMaxSkeletonAmountAsArray(): Array<number> {
    return Array(this.MAX_ROW_AMOUNT).fill(0);
  }

  refreshViewItems(): void {
    if (!this.treatmentsList) {
      this.viewList = [];
      return;
    }
    if (this.searchParams.criterions) {
      this.viewList = this.getSearchResults();
    } else {
      this.viewList = ItemSorterService.sort(this.treatmentsList, this.sortParams);
    }
    this.isLoading = false;
  }

  private getSearchResults(): TreatmentDetails[] {
    return ItemSearcherService.searchItems<TreatmentDetails>(
      this.searchHandler,
      this.treatmentsList,
      this.searchParams,
    );
  }

  checkSortParams(field: string, isAsc: boolean): boolean {
    const direction = isAsc ? SortDirection.ASC : SortDirection.DESC;
    return this.sortParams.field === field && this.sortParams.direction === direction;
  }

  setSort(field: string): void {
    if (this.sortParams.field === field) {
      this.sortParams.direction =
        this.sortParams.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      this.sortParams.direction = SortDirection.ASC;
    }
    this.sortParams.field = field;
    this.refreshViewItems();
  }

  async newTreatment(): Promise<void> {
    await this.loadingService.load(
      await firstValueFrom(
        this.translateService.get('shared.treatments.opening-treatment-creation-form'),
      ),
    );
    this.createTreatmentClicked.emit();
  }

  private initializeFilterAndSortParameters(): void {
    this.searchParams.fields = ['title', 'date', 'doctor', 'location'];
    this.searchHandler = this.searchService.configureMiniSearch<TreatmentDetails>(
      this.searchParams,
    );
    this.sortParams.field = 'date';
    this.sortParams.direction = SortDirection.DESC;
  }
}
