import { Component, EventEmitter, Output } from '@angular/core';
import { faArrowRight, faFingerprint, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { Invoker } from 'projects/core/src/lib/models/invoker-body.model';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';
import { AutoIdentService } from 'projects/core/src/lib/services/auto-ident.service';
import { PopupService } from 'projects/core/src/lib/services/popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-auto-ident-widget',
  templateUrl: './auto-ident.component.html',
  styleUrls: ['./auto-ident.component.scss'],
})
export class AutoIdentWidgetComponent {
  autoRefreshSub: Subscription;
  requiredActions: RequiredActionsDetails[] = [];
  translations: object;
  isLoading = true;

  @Output()
  afterAction = new EventEmitter<void>();

  readonly icons = {
    fingerprint: faFingerprint,
    hourglass: faHourglass,
    arrowRight: faArrowRight,
  };

  constructor(
    private popupService: PopupService,
    private autoIdentService: AutoIdentService,
  ) {}

  get autoIdentInvoker(): Invoker {
    return this.autoIdentService.autoIdentInvoker;
  }

  get isPending(): boolean {
    return this.autoIdentService.autoIdentPending;
  }

  get isUpdating(): boolean {
    return this.autoIdentService.autoIdentUpdatingDelay;
  }

  async showAutoIdentInformationModal() {
    await this.popupService.showAutoIdentInformationModal(this.autoIdentInvoker);
    this.afterAction.emit();
  }
}
