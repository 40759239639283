import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowRight, faCalendar, faInfo } from '@fortawesome/free-solid-svg-icons';
import { RequiredActionsDetails } from 'projects/core/src/lib/models/required-actions.model';

@Component({
  selector: 'lib-required-action-card',
  templateUrl: './required-action-card.component.html',
  styleUrls: ['./required-action-card.component.scss'],
})
export class RequiredActionCardComponent {
  @Input() requiredAction: RequiredActionsDetails;

  @Output() viewTaskInformation: EventEmitter<void> = new EventEmitter<void>();
  @Output() openRequiredAction: EventEmitter<void> = new EventEmitter<void>();

  readonly icons = {
    arrow: faArrowRight,
    info: faInfo,
    calendar: faCalendar,
  };
}
