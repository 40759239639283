<ion-card color="light" class="no-margin widget-card">
  <lib-auto-layout vertical="center" [gap]="[10]" [padding]="[15, 20, 10]" class="widget-header">
    <h2 data-api-id="last-activities-list" [grow]="1" class="no-margin">
      {{ 'last-activities.title' | translate }}
    </h2>
  </lib-auto-layout>
  <ion-card-content class="ion-no-padding">
    @if (!isLoading) {
      @if (slicedGroupedActivities?.length) {
        <ion-row class="outer-card">
          <ion-col id="last-activities">
            <ion-row class="activity-by-date-card" *ngFor="let activity of slicedGroupedActivities">
              <ion-col class="date-container ion-no-padding" size="12" sizeXl="3.5">
                <div class="line ion-hide-xl-down"></div>
                <div class="date-text">
                  <p>{{ activity.date | localeDate: 'longDate' }}</p>
                </div>
              </ion-col>
              <ion-col class="inner-card ion-no-padding" size="12" sizeXl="8.25">
                <ion-list>
                  <ion-item lines="none" *ngFor="let event of activity.activities">
                    <span class="date p" slot="start">
                      <span class="time"> {{ event.date | time: 'shortTime' : true }}</span>
                    </span>
                    <fa-icon
                      class="icon ion-text-center"
                      [icon]="lastActivityTypeToIcon(event.type)"></fa-icon>
                    <ion-label class="ion-text-wrap ion-no-margin">
                      <p>{{ event.title }}</p>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      } @else if (isDataAccessRestricted) {
        <lib-auto-layout direction="vertical" [grow]="1">
          <lib-notification-box-component
            [grow]="1"
            [message]="'last-activities.no-available-activities' | translate"
            [icon]="'user-lock'"
            [padding]="[40]"
            [background]="'tertiary'">
          </lib-notification-box-component>
        </lib-auto-layout>
      } @else {
        <lib-auto-layout direction="vertical" [padding]="[1, 0]" [grow]="1" class="no-results">
          <lib-notification-box-component
            [grow]="1"
            [message]="'last-activities.no-last-activities' | translate"
            [padding]="[40]">
          </lib-notification-box-component>
        </lib-auto-layout>
      }
    } @else {
      <ion-row id="skeleton" class="outer-card">
        <ion-col>
          <ion-row *ngFor="let i of [1, 2, 3]" class="activity-by-date-card ion-align-items-center">
            <ion-col class="date-container ion-no-padding" sizeXl="2.75" size="6">
              <ion-skeleton-text
                style="height: 25px; border-radius: var(--radius-small); margin: 0"
                animated></ion-skeleton-text>
            </ion-col>
            <ion-col size="0.75" class="ion-hide-xl-down"></ion-col>
            <ion-col class="inner-card ion-no-padding" sizeXl="8.25" size="12">
              <ion-skeleton-text
                style="height: 75px; border-radius: var(--radius-card); margin: 0"
                animated>
              </ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    }
  </ion-card-content>
</ion-card>
